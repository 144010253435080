<template>
  <div v-if="expenses && expenses.length > 0">
    <DashboardSection>
      <template #top-right-button>
        <CustomButton
          iconClass="plus"
          label="New expense"
          color="grey"
          size="small"
          @click="$router.push({ name: 'AddExpense' })"
        />
      </template>
      <template #content v-if="expenses && expenses.length > 0">
        <TableLayout>
          <template #left-headings>
            <p class="left-heading-item">Type</p>
            <p class="left-heading-item mobile-hide">Who</p>
            <p class="left-heading-item mobile-hide">ID</p>
            <p class="left-heading-item mobile-hide">Amount</p>
            <p class="left-heading-item mobile-hide">Date</p>
          </template>
          <template #right-headings>
            <p class="right-heading-item">Status</p>
          </template>

          <TableTile v-for="expense in expenses" :key="expense.id">
            <template #tileContent>
              <div class="expense-main" :class="getExpenseStatusClass(expense.status)">
                <div class="left-content">
                  <div class="left-text">{{ expense.type }}</div>
                  <div class="mobile-hide">{{ expense.uploadedByUser.fullName }}</div>
                  <div class="mobile-hide">{{ expense.id }}</div>
                  <div class="mobile-hide">{{ intlFormat(+expense.amount) }}</div>
                  <div class="mobile-hide">{{ dateFormatter(expense.createdAt) }}</div>
                </div>
                <div class="right-content">
                  <div>
                    <p class="status-main">{{ expense.status.charAt(0).toUpperCase() + expense.status.slice(1) }}</p>
                  </div>
                </div>
              </div>
            </template>
            <template #menu>
              <ul>
                <li @click="deleteExpense(expense.id)">Delete</li>
                <!-- <li v-on:click="clicked(), updateStatus(expense.id)">Change Status</li> -->
                <li
                  v-if="expense.status !== 'upcoming'"
                  @click="changeExpenseStatus(expense.id, ExpenseStatus.upcoming)"
                >
                  Change to Upcoming
                </li>
                <li v-if="expense.status !== 'due'" @click="changeExpenseStatus(expense.id, ExpenseStatus.due)">
                  Change to Due
                </li>
                <li v-if="expense.status !== 'Paid'" @click="changeExpenseStatus(expense.id, ExpenseStatus.Paid)">
                  Change to Paid
                </li>
              </ul>
            </template>
          </TableTile>
        </TableLayout>
      </template>
    </DashboardSection>
  </div>
  <div class="no-documents" v-else>
    <NoResult title="You haven't added any invoices yet">
      <CustomButton @click="$router.push({ name: 'AddExpense' })" label="Add a new expense" color="blue" />
    </NoResult>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import DashboardSection from '@/components/atom/DashboardSection.vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import TableLayout from '@/components/templates/TableLayout.vue'
import TableTile from '@/components/templates/TableTile.vue'
import NoResult from '@/components/molecules/NoResult.vue'
import authApi from '@/api/Authenticated'
import { useRoute } from 'vue-router'
import useDateFormatter from '@/utils/dateFormatter'
import { projectTypes } from '@shared/*'
import { intlFormat } from '@/utils/currencyFunctions'

export default defineComponent({
  name: 'ProjectExpenses',
  components: {
    DashboardSection,
    CustomButton,
    TableLayout,
    TableTile,
    NoResult
  },
  setup() {
    const route = useRoute()
    const projectId = +route.params.id
    const { dateFormatter } = useDateFormatter()
    const expenses = ref<projectTypes.ProjectExpenseResponse[]>([])
    onMounted(async () => {
      expenses.value = await authApi.getProjectExpenses(projectId)
    })
    enum ExpenseStatus {
      upcoming = 'upcoming',
      due = 'due',
      Paid = 'Paid'
    }
    const deleteExpense = async (expenseId: number) => {
      const res = await authApi.deleteExpense(+projectId, +expenseId)
      if (res) {
        const index = expenses.value.findIndex((e) => e.id === expenseId)
        if (index !== -1) {
          expenses.value.splice(index, 1)
        }
      }
    }

    const isStatusChanging = ref(false)
    const currentId = ref(0)
    const updateExpenseStatus = async (expenseId: number, Expensestatus: projectTypes.ProjectExpenseStatusPutBody) => {
      const res = await authApi.updateProjectExpenseStatus(+projectId, +expenseId, Expensestatus)
      if (res) {
        expenses.value = await authApi.getProjectExpenses(projectId)
      }
    }
    const updateStatus = (expenseId: number) => {
      isStatusChanging.value = true
      currentId.value = expenseId
    }

    const changeExpenseStatus = (id: number, status: ExpenseStatus) => {
      const obj = {
        status: status
      }
      updateExpenseStatus(id, obj)
      isStatusChanging.value = false
    }

    const getExpenseStatusClass = function (status: string) {
      if (status === 'Paid' || status === 'upcoming') {
        return 'status-green'
      } else {
        return 'status-red'
      }
    }

    return {
      DashboardSection,
      expenses,
      dateFormatter,
      deleteExpense,
      updateStatus,
      updateExpenseStatus,
      isStatusChanging,
      currentId,
      changeExpenseStatus,
      getExpenseStatusClass,
      ExpenseStatus,
      intlFormat
    }
  }
})
</script>

<style lang="sass" scoped>
$leftWidth: 8rem
$rightWidth: 5rem
.tile-tile
  font-weight: bold
  font-size: 16px
.tile-subtitle
  font-size: 12px
  color: map-get($greyShades, 'greyShade80')
.left-content, .right-content
  display: flex
  align-items: center
  & > *
    text-align: center
    color: $primary
    font-size: 14px
.left-content > *
  min-width: $leftWidth
  & > *
    padding-left: 2rem
.right-content
  margin-right: 1rem
.right-content > *
  min-width: $rightWidth
.right-heading-item
  min-width: $rightWidth
.left-heading-item
  min-width: $leftWidth
  cursor: pointer
  display: flex
  align-items: center
  justify-content: center
.left-text
  text-align: left
  color: $primary
  margin-left: 0.5rem
  padding-left: 2rem
.mobile-hide
  @media( max-width: $md-breakpoint)
    display: none
.expense-main
  border-left: 4px solid
  border-radius: 4px
.status-red
  border-color: $red
  .status-main
    background-color: $red
.status-yellow
  border-color: $mustard
  .status-main
    background-color: $mustard
.status-grey
  border-color: map-get($greyShades, 'greyShade30')
  .status-main
    background-color: map-get($greyShades, 'greyShade30')
.status-green
  border-color: $green
  .status-main
    background-color: $green
.status-main
  display: flex
  padding: 0.5rem
  border-radius: 2px
  color: $white
  margin: 0
  justify-content: center
.highlight
  font-weight: bold
  font-size: 16px
.mobile-view
  display: none
  @media( max-width: $md-breakpoint)
    display: block
</style>
