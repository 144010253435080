
import { defineComponent, onMounted, ref } from 'vue'
import DashboardSection from '@/components/atom/DashboardSection.vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import TableLayout from '@/components/templates/TableLayout.vue'
import TableTile from '@/components/templates/TableTile.vue'
import NoResult from '@/components/molecules/NoResult.vue'
import authApi from '@/api/Authenticated'
import { useRoute } from 'vue-router'
import useDateFormatter from '@/utils/dateFormatter'
import { projectTypes } from '@shared/*'
import { intlFormat } from '@/utils/currencyFunctions'

export default defineComponent({
  name: 'ProjectExpenses',
  components: {
    DashboardSection,
    CustomButton,
    TableLayout,
    TableTile,
    NoResult
  },
  setup() {
    const route = useRoute()
    const projectId = +route.params.id
    const { dateFormatter } = useDateFormatter()
    const expenses = ref<projectTypes.ProjectExpenseResponse[]>([])
    onMounted(async () => {
      expenses.value = await authApi.getProjectExpenses(projectId)
    })
    enum ExpenseStatus {
      upcoming = 'upcoming',
      due = 'due',
      Paid = 'Paid'
    }
    const deleteExpense = async (expenseId: number) => {
      const res = await authApi.deleteExpense(+projectId, +expenseId)
      if (res) {
        const index = expenses.value.findIndex((e) => e.id === expenseId)
        if (index !== -1) {
          expenses.value.splice(index, 1)
        }
      }
    }

    const isStatusChanging = ref(false)
    const currentId = ref(0)
    const updateExpenseStatus = async (expenseId: number, Expensestatus: projectTypes.ProjectExpenseStatusPutBody) => {
      const res = await authApi.updateProjectExpenseStatus(+projectId, +expenseId, Expensestatus)
      if (res) {
        expenses.value = await authApi.getProjectExpenses(projectId)
      }
    }
    const updateStatus = (expenseId: number) => {
      isStatusChanging.value = true
      currentId.value = expenseId
    }

    const changeExpenseStatus = (id: number, status: ExpenseStatus) => {
      const obj = {
        status: status
      }
      updateExpenseStatus(id, obj)
      isStatusChanging.value = false
    }

    const getExpenseStatusClass = function (status: string) {
      if (status === 'Paid' || status === 'upcoming') {
        return 'status-green'
      } else {
        return 'status-red'
      }
    }

    return {
      DashboardSection,
      expenses,
      dateFormatter,
      deleteExpense,
      updateStatus,
      updateExpenseStatus,
      isStatusChanging,
      currentId,
      changeExpenseStatus,
      getExpenseStatusClass,
      ExpenseStatus,
      intlFormat
    }
  }
})
